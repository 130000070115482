import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1200px;
  margin: auto;
  overflow: visible;
  animation: fadeIn 0.2s ease-in;
`;

export default Container;
