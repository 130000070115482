import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import reducer from './reducers';

const middleware = [...getDefaultMiddleware()];

const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: window.___STORE___,
});

export default store;
