import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalTransition } from '@atlaskit/modal-dialog';

import { actions as modalActions } from '../store/reducers/modal';

import Loading from './Loading';

export default function Modal() {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);
  const onClose = () => dispatch(modalActions.closeModal());
  const Component = modal.component
    ? React.lazy(() => import('./' + modal.component))
    : () => null;

  return (
    <React.Suspense fallback={<Loading />}>
      <ModalTransition>
        {modal.component && (
          <Component
            onClose={onClose}
            shouldCloseOnEscapePress={false}
            {...modal.props}
          />
        )}
      </ModalTransition>
    </React.Suspense>
  );
}
