import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'modal',
  initialState: { component: null, props: null },
  reducers: {
    setModal: (state, action) => {
      state.component = action.payload.component;
      state.props = action.payload.props;
    },
    closeModal(state) {
      state.component = null;
      state.props = null;
    },
  },
});

export const actions = slice.actions;

export default slice;
