import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';

import { get } from './lib/api';
import { actions as accountActions } from './store/reducers/account';

import * as serviceWorker from './serviceWorker';

import './index.css';

const { REACT_APP_IDENTITY_URL } = process.env;

tryLogin().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
});

async function tryLogin() {
  const res = await get(REACT_APP_IDENTITY_URL + '/v1/users/me');
  if (res.status === 401) {
    window.location = REACT_APP_IDENTITY_URL + '/v1/users/login?next=/v1/apps/vendors';
  }

  const profile = await res.json();
  profile.isAdmin = profile.roles.includes('admin');
  store.dispatch(accountActions.setProfile(profile));
  // await Promise.race([
  //   async () => {
  //   },
  // sleep(5000),
  // ]);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
