import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import './App.css';

import Header from './components/Header';
import Loading from './components/Loading';
import Modal from './components/Modal';

const Dashboard = React.lazy(() => import('./pages/DashboardPage'));

const StyledApp = styled.div`
  @media (max-width: 1232px) {
    // padding-left: 1rem;
    // padding-right: 1rem;
  }
`;
function App() {
  return (
    <Router>
      <StyledApp>
        <Header />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/" exact component={Dashboard} />
          </Switch>
        </Suspense>
        <Modal />
      </StyledApp>
    </Router>
  );
}

export default App;
