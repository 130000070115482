import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'account',
  initialState: { profile: null, lastUpdated: Date.now() },
  reducers: {
    setLastUpdated: state => {
      state.lastUpdated = Date.now();
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const actions = slice.actions;

export default slice;
