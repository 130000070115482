import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'advertisements',
  initialState: { advertisements: [], lastUpdated: Date.now() },
  reducers: {
    setLastUpdated: state => {
      state.lastUpdated = Date.now();
    },

    setAdvertisements: (state, action) => {
      if (!Array.isArray(action.payload)) return;

      state.advertisements = action.payload;
    },
  },
});

export const actions = slice.actions;

export default slice;
