import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import Button from '@atlaskit/button';

import Container from './Container';

const { REACT_APP_HUB_URL, REACT_APP_IDENTITY_URL } = process.env;

const StyledHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #2a3585;
  color: #fff;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  height: ${props => (props.small ? '40px;' : '64px')};
  overflow: visible;
  width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 3px solid transparent;
  font-size: 0.875rem;

  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }

  &.active {
    color: #fafafa;
    font-weight: 500;
    border-bottom-color: #07ab89;
  }
`;

export default function Header(props) {
  const profile = useSelector(state => state.account.profile);
  return (
    <StyledHeader>
      <StyledContainer style={{ justifyContent: 'space-between' }}>
        <img src="/img/vei-logo.svg" alt="Certified Vendors | VEI" height="54px" />
        {profile && (
          <DropdownMenu
            trigger={
              <Button
                theme={(currentTheme, themeProps) => {
                  const { buttonStyles, spinnerStyles } = currentTheme(themeProps);
                  return {
                    buttonStyles: {
                      ...buttonStyles,
                      color: 'rgba(255, 255, 255, 0.5)',
                    },
                    spinnerStyles,
                  };
                }}>
                {`${profile.first_name} ${profile.last_name.slice(0, 1)}`}
              </Button>
            }
            style={{
              color: '#fff',
            }}
            css={{ color: '#fff' }}
            onOpenChange={e => console.log('dropdown opened', e)}>
            <DropdownItemGroup>
              <DropdownItem as="a" href={REACT_APP_HUB_URL}>
                Return to Hub
              </DropdownItem>
              <DropdownItem as="a" href={REACT_APP_IDENTITY_URL + '/v1/users/logout'}>
                Logout
              </DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        )}
      </StyledContainer>
      <StyledContainer small>
        <StyledNavLink to="/" exact activeClassName="active">
          Dashboard
        </StyledNavLink>
        {/* <StyledNavLink to="/documentation" activeClassName="active">
          Documentation
        </StyledNavLink> */}
      </StyledContainer>
    </StyledHeader>
  );
}
