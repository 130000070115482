import { combineReducers } from '@reduxjs/toolkit';

import modal from './modal';
import advertisements from './advertisements';
import account from './account';

const reducer = combineReducers({
  modal: modal.reducer,
  advertisements: advertisements.reducer,
  account: account.reducer,
});

export default reducer;
