import merge from 'lodash/merge';
import fetch from 'isomorphic-fetch';

const { REACT_APP_API_URL } = process.env;

const client = (url, config) => {
  if (!url.startsWith('http')) {
    url = REACT_APP_API_URL + url;
  }
  const mergedConfig = merge(
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    config,
  );
  return fetch(url, mergedConfig);
};

export const get = (url, config) => client(url, config);
export const head = (url, config) => client(url, merge({ method: 'HEAD' }, config));
export const patch = (url, config) => client(url, merge({ method: 'PATCH' }, config));
export const post = (url, config) => client(url, merge({ method: 'POST' }, config));
export const del = (url, config) => client(url, merge({ method: 'DELETE' }, config));

export default client;
